import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "@popperjs/core/dist/umd/popper.min.js";
import './src/scss/styles.scss'

import yourScript from './src/libs/alphaHover.js'


//export const onInitialClientRender= () => yourScript;


export const onRouteUpdate = () => {
    const heading = document.querySelector(".heading");
    if (typeof(heading) != 'undefined' && heading != null) {
        yourScript.init(heading);
    }

}
